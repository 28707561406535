import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { AnimatePresence } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import search from "../images/search.svg"
import Container from "./container"
import H3 from "./h3"
import Input from "./input"
import Button from "./button"
import WomanCard from "./woman-card"
import OrangeFlower from "./orange-flower"
import Overlay from "./overlay"
import getTranslation from "../translations"

const StoriesList = ({ data, id, lang }) => {
  const isBrowser = typeof window !== "undefined"
  let params = null
  if (isBrowser) {
    params = new URLSearchParams(window.location.search)
  }
  const pageSize = 15
  const [sortyBy, setSortBy] = useState("featured")
  const [filteredList, setFilteredList] = useState([])
  const [paginatedList, setPaginatedList] = useState([])
  const [searchText, setSearchText] = useState("")
  const [noResults, setNoResults] = useState(false)
  const [filter, setFilter] = useState(
    (params && params.get("filter")) || "ALL"
  )
  const [count, setCount] = useState(pageSize)
  const [ref, inView] = useInView()
  const [selectedStory, setSelectedStory] = useState(null)
  const allStories = useStaticQuery(graphql`
    query StoriesQueryAll {
      en: allSanityStory(
        sort: { fields: featured, order: ASC }
        filter: { i18n_lang: { eq: "en" } }
      ) {
        nodes {
          title
          excerpt
          featured
          i18n_lang
          slug {
            current
          }
          personName
          personTitle
          quote
          featuredImage {
            asset {
              gatsbyImageData
              url
            }
          }
          featuredImageGray {
            asset {
              gatsbyImageData
              url
            }
          }
          tags {
            value
            label
          }
          body {
            _rawChildren
          }
        }
      }
      ar: allSanityStory(
        sort: { fields: featured, order: ASC }
        filter: { i18n_lang: { eq: "ar" } }
      ) {
        nodes {
          title
          excerpt
          featured
          i18n_lang
          slug {
            current
          }
          personName
          personTitle
          quote
          featuredImage {
            asset {
              gatsbyImageData
              url
            }
          }
          featuredImageGray {
            asset {
              gatsbyImageData
              url
            }
          }
          tags {
            value
            label
          }
          body {
            _rawChildren
          }
        }
      }
    }
  `)
  const list = allStories[lang].nodes

  useEffect(() => {
    setNoResults(false)
    if (filteredList.length === 0) {
      setPaginatedList([])
      setNoResults(true)
      return
    }
    setPaginatedList([...filteredList].slice(0, count))
  }, [filteredList, count])

  useEffect(() => {
    let filtered = []
    if (sortyBy !== "featured") {
      if (sortyBy === "az") {
        filtered = [...list].sort((a, b) => a.title.localeCompare(b.title))
      } else {
        filtered = [...list].sort((a, b) => b.title.localeCompare(a.title))
      }
    } else {
      filtered = [...list]
    }
    if (filter === "ALL") {
      if (searchText !== "") {
        filtered = [...filtered].filter(item =>
          item.title.toLowerCase().includes(searchText.toLowerCase())
        )
      }
    } else {
      if (searchText === "") {
        filtered = [...filtered].filter(
          item =>
            item.tags
              .map(x => x.value.toLowerCase())
              .indexOf(filter.toLowerCase().trim()) > -1
        )
      } else {
        filtered = [...filtered].filter(
          item =>
            item.tags
              .map(x => x.value.toLowerCase())
              .indexOf(filter.toLowerCase().trim()) > -1 &&
            item.title.toLowerCase().includes(searchText.toLowerCase())
        )
      }
    }
    setFilteredList([...filtered])
    setCount(pageSize)
  }, [filter, searchText, sortyBy])

  return (
    <>
      <Container
        classes="bg-blue flex flex-col relative pb-0 "
        id={id}
        lang={lang}
      >
        <H3 classes="text-orange my-[2.5rem] mt-[4rem] xl:mt-[8rem] xl:w-2/3">
          {data.title}
        </H3>
        <div className="flex flex-row justify-start items-center w-full lg:w-1/2 border-b border-orange pt-[2rem]">
          <div>
            <img src={search} className="w-[32px] h-[32px]" alt="search" />
          </div>
          <Input
            onChange={e => setSearchText(e.target.value)}
            type="text"
            name="search"
            id="search"
            placeholder={getTranslation("Search by name", lang)}
            classes="bg-transparent border-transparent text-orange w-full text-lg placeholder-orange placeholder:text-[0.8rem] lg:placeholder:text-[1.1rem] 2xl:placeholder:text-[1.6rem] 2xl:text-[1.6rem] font-normal px-4 py-4"
          />
        </div>
        <div className="flex flex-wrap flex-col lg:flex-row lg:justify-start lg:items-start mt-[3rem] mb-[2rem] lg:mt-[5rem] lg:mb-[3rem]">
          <div>
            {data.filters.split(",").map((f, index) => (
              <Button
                onClick={() => setFilter(f)}
                key={`filter_${index}`}
                variation={
                  filter === f ? `outlineSecondarySelected` : `outlineSecondary`
                }
                additionalClasses={`mb-4 px-[1rem] py-[0.5rem] ml-0 mr-3 rtl:mr-0 rtl:ml-3`}
              >
                {getTranslation(f, lang)}
              </Button>
            ))}
          </div>
          <div className="lg:ml-auto rtl:lg:ml-0 rtl:lg:mr-auto pr-1">
            <select
              className="bg-transparent text-sm md:text-base text-orange mt-4 lg:mt-0 lg:py-8 pr-2 rtl:pr-0 rtl:pl-2 lg:mb-2"
              onChange={e => setSortBy(e.target.value)}
            >
              <option value="az">{getTranslation("A - Z", lang)}</option>
              <option value="za">{getTranslation("Z - A", lang)}</option>
            </select>
          </div>
        </div>
        <div className="mb-[20rem] md:mb-[25rem] xl:mb-[32rem] 2xl:mb-[35rem]">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
            {paginatedList.map((item, index) => (
              <WomanCard
                key={"WomanCard_" + index}
                item={item}
                onClick={() => setSelectedStory(item)}
              ></WomanCard>
            ))}
            {noResults && (
              <div className="col-span-2 md:col-span-3 text-center text-orange text-[1.2rem] py-40 pt-10">
                {getTranslation("No results found", lang)}
              </div>
            )}
          </div>
          {count < filteredList.length && (
            <div className="mt-[4rem] ">
              <Button
                variation="secondary"
                additionalClasses="px-[1.2rem] py-[0.6rem]"
                onClick={() => setCount(count + pageSize)}
              >
                {getTranslation("SHOW MORE", lang)} ({list.length - count})
              </Button>
            </div>
          )}
        </div>
        <div
          ref={ref}
          className="absolute z-10 bottom-[-1px] right-[20%] lg:right-[30%] 2xl:right-[20%] rtl:right-auto rtl:left-[20%] rtl:lg:left-[30%] rtl:2xl:left-[20%]"
        >
          <OrangeFlower
            inView={inView}
            className="h-[300px] md:h-[450px] xl:h-[500px] 2xl:h-[600px]"
          />
        </div>
      </Container>
      <AnimatePresence>
        {selectedStory && (
          <Overlay
            lang={lang}
            item={selectedStory}
            onClose={() => setSelectedStory(null)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default StoriesList
