import React, { useEffect, useState } from "react"
import { motion, useAnimationControls } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import Button from "./button"
import H3 from "./h3"
import Quote from "./quote"
import refresh from "../images/refresh.svg"
import Container from "./container"

const QuoteList = ({ data, id, lang }) => {
  const NUMBER_OF_QUOTES = 3
  const [randomQuotes, setRandomQuotes] = useState([])
  const controls = useAnimationControls()
  const allQuotes = useStaticQuery(graphql`
    query QuotesQuery {
      en: allSanityStory(filter: { i18n_lang: { eq: "en" } }) {
        nodes {
          title
          personTitle
          quote
        }
      }
      ar: allSanityStory(filter: { i18n_lang: { eq: "ar" } }) {
        nodes {
          title
          personTitle
          quote
        }
      }
    }
  `)
  const quotes = allQuotes[lang].nodes
  const getMultipleRandomQuotes = () => {
    const shuffled = [...quotes].sort(() => 0.5 - Math.random())
    setRandomQuotes(shuffled.slice(0, NUMBER_OF_QUOTES))
    controls.start({
      opacity: [0, 1],
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    })
  }

  useEffect(() => {
    getMultipleRandomQuotes()
  }, [])

  return (
    <Container classes="bg-blue pb-[5rem] md:pb-[8rem]" id={id} lang={lang}>
      <div className="flex flex-row justify-start items-start">
        <H3 classes="text-orange pb-[2.5rem] xl:pb-[5rem]">{data.title}</H3>
        <Button
          variation="iconSecondary"
          onClick={getMultipleRandomQuotes}
          additionalClasses="rtl:pt-[5px]"
        >
          <motion.img
            initial={{ rotate: 0, scale: 1 }}
            whileHover={{ rotate: 160, scale: 1.2 }}
            src={refresh}
            alt="load more"
            className="lg:mx-4 w-[24px] xl:w-[32px] 2xl:w-[40px]"
          />
        </Button>
      </div>
      <motion.div animate={controls}>
        {randomQuotes.map((quote, index) => (
          <Quote
            key={`quote-${index}`}
            quote={quote.quote}
            variation="secondary"
            name={quote.name}
            title={quote.title}
            nameOnTop={true}
            textColor="text-orange"
          ></Quote>
        ))}
      </motion.div>
    </Container>
  )
}

export default QuoteList
