import React from "react"
import { useInView } from "react-intersection-observer"
import H3 from "./h3"
import Container from "./container"
import P from "./p"
import H4 from "./h4"
import OrangeFlower from "./orange-flower"

const About = ({ data, id, lang }) => {
  const [ref, inView] = useInView()

  return (
    <Container classes="bg-blue relative pb-0" id={id} lang={lang}>
      <H3 classes="text-orange my-[2.5rem] mt-0 lg:pt-8 xl:pt-16">
        {data.title}
      </H3>
      <div className="pb-[300px] lg:pb-[150px] 2xl:pb-[200px]">
        {data.aboutBody.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-start items-start border-t border-orange lg:flex-row"
          >
            <H4 classes="text-orange 2xl:leading-[1.8rem] pt-8 lg:w-1/4 ">
              {item.title}
            </H4>
            <P
              classes={`text-orange lg:pl-8 rtl:lg:pl-0 rtl:lg:pr-8 py-8 ${
                index < data.aboutBody.length - 1
                  ? "lg:w-3/4"
                  : "lg:w-1/3 lg:mb-[8rem]"
              }`}
            >
              {item.text}
            </P>
          </div>
        ))}
      </div>
      <div
        ref={ref}
        className="absolute z-10 bottom-[-1px] right-1/2 transform translate-x-1/2 lg:translate-x-0 lg:right-[10%] rtl:lg:right-auto rtl:lg:left-[10%]"
      >
        <OrangeFlower
          inView={inView}
          className="h-[300px] md:h-[250px] lg:h-[638px]"
        />
      </div>
    </Container>
  )
}

export default About
