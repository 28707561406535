import React from "react"
import { useInView } from "react-intersection-observer"
import ParallaxText from "./parallex-text"
import H3 from "./h3"
import Container from "./container"
import Form from "./form"
import OrangeFlower from "./orange-flower"
import getTranslation from "../translations"

const ContactUS = ({ data, id, lang, type }) => {
  const [ref, inView] = useInView()

  return (
    <div className="relative overflow-hidden" id={id} lang={lang}>
      <Container classes="bg-orange flex justify-center items-center lg:pt-[10rem] pt-[5rem] pb-[5rem]">
        <div className="bg-white rounded-[40px] relative z-20">
          <div className="px-[2rem] py-[3rem] lg:px-[5rem] lg:py-[5rem] lg:pb-[3rem]">
            <H3 classes="text-blue leading-[2.5rem]">{data.title}</H3>
            <Form
              fields="name,email,message,consent"
              type={type}
              lang={lang}
              submit="SUBMIT MESSAGE"
            />
          </div>
          <div className="border-t-[1px] border-orange"></div>

          <div className="flex flex-col lg:flex-row items-start lg:pr-[3rem]">
            <div className="flex-grow px-[1.5rem] py-[2rem] lg:pl-[5rem] rtl:lg:pl-0 rtl:lg:pr-[2rem] lg:pr-[0rem] lg:py-[5rem]">
              <div className="text-blue font-bold text-lg md:text-[1.56rem] pb-4">
                {getTranslation("Email", lang)}
              </div>
              <div className="text-blue text-lg md:text-[1.56rem]">
                {data.email}
              </div>
            </div>
            <div className="flex-grow px-[1.5rem] pb-[4rem] lg:px-[0rem] lg:py-[5rem]">
              <div className="text-blue font-bold text-lg md:text-[1.56rem] pb-4">
                {getTranslation("Phone Number", lang)}
              </div>
              <div
                dir="auto"
                className="text-blue text-lg md:text-[1.56rem] rtl:text-right"
              >
                {data.phone}
              </div>
            </div>
          </div>
          <div
            ref={ref}
            className="absolute z-10 bottom-[-1px] right-[15%] md:right-[20%] lg:right-[10%] rtl:right-auto rtl:left-[15%] rtl:md:left-[20%] rtl:lg:left-[10%]"
          >
            <OrangeFlower
              inView={inView}
              className="h-[200px] md:h-[250px] lg:h-[150px] "
            />
          </div>
        </div>
      </Container>
      <div className="absolute z-10 top-0 left-0">
        <div className="font-bold text-[9rem] mt-[-1rem] xl:mt-[-3rem] xl:text-[18rem] opacity-10 leading-[10rem] xl:leading-[18rem] tracking-[0.02rem] text-blue ">
          <ParallaxText baseVelocity={-0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={-0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={-0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={-0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={0.3}>Scroll velocity</ParallaxText>
          <ParallaxText baseVelocity={-0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={0.3}>Scroll velocity</ParallaxText>
          <ParallaxText baseVelocity={-0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={0.3}>Scroll velocity</ParallaxText>
          <ParallaxText baseVelocity={-0.3}>100 QATARI WOMEN</ParallaxText>
          <ParallaxText baseVelocity={0.3}>Scroll velocity</ParallaxText>
        </div>
      </div>
    </div>
  )
}

export default ContactUS
