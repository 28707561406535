import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { MdClose } from "react-icons/md"
import H3 from "./h3"
import H4 from "./h4"
import P from "./p"
import Button from "./button"
import H2 from "./h2"
import getTranslation from "../translations"

const Overlay = ({ item, onClose, lang }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    document.body.scroll = "no"
    return () => {
      document.body.style.overflow = "auto"
      document.body.scroll = "yes"
    }
  }, [])

  return (
    <>
      <motion.div
        initial={{ opacity: 0.4, top: "-100%" }}
        animate={{ opacity: 1, top: 0 }}
        exit={{ opacity: 0.4, top: "-100%" }}
        transition={{ duration: 0.5, ease: "easeIn" }}
        className="lg:hidden overflow-hidden bg-orange fixed top-0 left-0 z-[1000] right-0 bottom-0 flex flex-col justify-start items-stretch lg:flex-row"
      >
        <button
          className="absolute right-0 top-0 py-4 px-4 z-50 text-orange bg-blue"
          onClick={onClose}
        >
          <MdClose size={30} className="text-orange" />
        </button>

        <GatsbyImage
          alt={item.title}
          image={item.featuredImageGray.asset.gatsbyImageData}
        />
        <div className="px-4 py-4">
          <H3 classes="text-blue">{item.title}</H3>
          <H4 classes="text-blue pb-4 text-base">{item.title}</H4>
          <P classes="text-blue text-xs">{item.excerpt}</P>
        </div>
        <div className="mt-4 mb-8 mx-4">
          <Button
            to={`/${lang}/100-qatari-women/${item.slug.current}`}
            variation="primary"
          >
            {getTranslation("READ HER STORY", lang)}
          </Button>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, translateX: "-100%" }}
        animate={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: "-100%" }}
        transition={{ duration: 0.4, ease: "easeIn" }}
        className="hidden lg:flex bg-orange fixed top-0 left-0 w-screen h-screen overflow-hidden z-[10000]"
      >
        <div className="lg:w-[550px] xl:w-[650px] 2xl:w-[850px] h-screen flex-shrink-0">
          <GatsbyImage
            alt={item.title}
            className="w-[100%] h-[100%] object-cover"
            image={item.featuredImage.asset.gatsbyImageData}
          />
        </div>
        <div className="ml-[6rem] mr-[4rem] my-[4rem] xl:my-[4rem] 2xl:my-[8rem] flex flex-col">
          <div>
            <H2 classes="text-blue pb-5">{item.title}</H2>
            <blockquote className="text-blue text-[1.6rem] xl:text-[1.6rem] 2xl:text-[2.1rem] italic">
              {item.quote}
            </blockquote>
            <p className="text-blue py-8 text-[1.1rem] xl:text-[1rem] 2xl:text-[1.4rem]">
              {item.excerpt}
            </p>
          </div>
          <div className="pt-8 2xl:pt-10 mx-[-0.2rem]">
            <Button
              to={`/${lang}/100-qatari-women/${item.slug.current}`}
              variation="primary"
            >
              {getTranslation("READ HER STORY", lang)}
            </Button>
          </div>
        </div>
        <button
          className="absolute right-0 top-0 px-8 py-8 z-[100000] text-blue hover:bg-blue hover:text-orange transition-all"
          onClick={onClose}
        >
          <MdClose size={35} />
        </button>
      </motion.div>
    </>
  )
}

export default Overlay
