import React from "react"
import { motion } from "framer-motion"

const commonClasses =
  "text-[0.8rem] leading-[1.2rem] tracking-[0.05rem] md:text-[1rem] md:leading-[1.2rem] md:tracking-[0.125rem] font-bold rtl:tracking-[0rem] rtl:md:tracking-[0rem]"

const H5 = ({ classes, children }) => {
  return (
    <motion.h5
      className={classes + " " + commonClasses}
      initial={{ opacity: 0, translateY: "2rem" }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.h5>
  )
}

export default H5
