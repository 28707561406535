import React from "react"
import { useInView } from "react-intersection-observer"
import Container from "./container"
import H3 from "./h3"
import ReactHtmlParser from "react-html-parser"
import OrangeFlower from "./orange-flower"

const WhySponsor = ({ data, id, lang }) => {
  const [ref, inView] = useInView()

  return (
    <Container
      classes="bg-blue mt-0 pt-[0rem] lg:pt-[0rem] pb-0"
      id={id}
      lang={lang}
      containerClasses="pt-[0rem]"
    >
      <div className="lg:w-3/5 2xl:w-2/3">
        <H3 classes="hidden lg:block text-orange pb-12 ">
          {ReactHtmlParser(data.title.replace("<br />", ""))}
        </H3>
        <H3 classes="lg:hidden text-orange pb-12 ">
          {ReactHtmlParser(data.title)}
        </H3>
        <div className="border-top border-orange border-t-[1px] pt-10 pb-[350px] md:pb-[500px] lg:pb-[180px]">
          <ol className="text-lightOrange list-decimal pl-4 rtl:pl-0 rtl:pr-4">
            {data.whySponsorBody.map((item, index) => (
              <li
                key={`item_sponor + ${index}`}
                className="pl-6 md:pl-8 xl:pl-12 pb-6 xl:pb-12 text-[0.8rem] md:text-[1.1rem] leading-[1.3rem] md:leading-[1.6rem] xl:text-[1.125rem] xl:leading-[1.5rem]"
              >
                {item}
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div
        ref={ref}
        className="scale-x-[-1] absolute z-10 bottom-[-1px] rtl:right-auto right-[40%] lg:right-[5%] 2xl:right-[0%] rtl:left-[40%] rtl:lg:left-[5%] rtl:2xl:left-[0%]"
      >
        <OrangeFlower
          inView={inView}
          className="h-[300px] md:h-[450px] lg:h-[650px] xl:h-[700px] 2xl:h-[750px]"
        />
      </div>
    </Container>
  )
}

export default WhySponsor
