import React from "react"

const Input = ({ type, name, id, placeholder, classes, onChange }) => {
  const inputClassses =
    "border-0 border-b-[1px] border-blue focus:outline-none focus:border-blue text-blue text-sm font-bold tracking-wider py-[0.4rem]"

  return (
    <input
      onChange={onChange}
      className={classes + " " + inputClassses}
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
    />
  )
}
export default Input
