import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"

const variants = {
  visible: { scale: 1, transition: { duration: 1.5, delay: 0.2 } },
  hidden: { scale: 0 },
}

const Shape = ({ className, type, cx, cy, r, d, inView }) => {
  const controls = useAnimation()
  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])
  return type === "circle" ? (
    <motion.circle
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
      cx={cx}
      cy={cy}
      r={r}
    />
  ) : (
    <motion.path
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
      d={d}
    />
  )
}

const OrangeFlower = ({ className, inView }) => {
  return (
    <svg viewBox="0 0 456.53 1006" className={className}>
      <defs>
        <style>{`.cls-1{fill:#f6d8bf;}.cls-2{fill:#ee3d96;}.cls-3{fill:#16becf;}.cls-4{fill:#702f83;}.cls-5{fill:#006fab;}.cls-6{fill:#ee3f35;}.cls-7{fill:#87c879;}.cls-8{fill:#fdb933;}`}</style>
      </defs>
      <path
        className="cls-1"
        d="M20.85,295.13a223.31,223.31,0,0,0,86.2,253,221.82,221.82,0,0,0,167.16,35.31,223.16,223.16,0,0,0,41.24-426.87A56.82,56.82,0,0,1,311,171a208,208,0,0,1,93.88,310.61,213.3,213.3,0,0,1-16.69,21.22L384.51,507l1.69-5.33c15.3-48.21,4.94-104.5,2.71-115.29C376.56,326.69,340.67,286,316.78,264.62a26.4,26.4,0,0,1-11.56,9.93c22.3,19.57,57.14,58,68.9,114.91.95,4.56,22.56,112.14-32,151.6l-1.71,1.24-.18-2.1a429.84,429.84,0,0,0-25.77-114.61,428.52,428.52,0,0,0-51.54-96.77A42.9,42.9,0,0,1,251,338.17a412.94,412.94,0,0,1,49.3,92.75A415.26,415.26,0,0,1,325.71,548.8l.18,2.73-2.12-1.71c-40.13-32.23-72-66-94.59-100.48C203,409.51,184.93,361.41,176.6,310a30.34,30.34,0,0,1-12.52,2.93c-.8,0-1.55-.16-2.33-.21,8.7,53.44,27.52,103.46,54.81,145,21.43,32.6,50.49,64.43,86.39,94.62l2.35,2-3.07.13c-42.66,1.84-93.75-27.06-125.46-56.52-37.79-35.09-53.33-78.3-59.69-110.48A40.67,40.67,0,0,1,104,389.75c-.61,0-1.2-.05-1.81-.07,6.73,34.52,23.39,81.2,64.34,119.24,19.47,18.08,53,41.57,88.59,53.27,5,1.63,10.12,3,15.28,4.21l5.75,1.29-5.8,1.06A208.21,208.21,0,0,1,34.92,300.65Z"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-2"
        cx="81.82"
        cy="177.16"
        r="17.65"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-3"
        cx="60.43"
        cy="82.53"
        r="17.31"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-4"
        cx="51.44"
        cy="133.31"
        r="10.01"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-5"
        cx="65.67"
        cy="221.31"
        r="10.01"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-3"
        cx="171.04"
        cy="31.65"
        r="21.7"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-6"
        cx="138.27"
        cy="83.2"
        r="15.87"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-2"
        cx="119.55"
        cy="9.13"
        r="9.58"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="30.45"
        cy="185.68"
        r="13.61"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="95.01"
        cy="44.77"
        r="9.61"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="228.85"
        cy="91.96"
        r="9.61"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-6"
        cx="156.71"
        cy="197.27"
        r="25.2"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-4"
        cx="108.76"
        cy="237.3"
        r="11"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="189.86"
        cy="141.1"
        r="21.7"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-5"
        cx="221.2"
        cy="234.02"
        r="13.61"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-2"
        d="M303.62,142.86a57.27,57.27,0,0,1-75.3,55.19l35.81-109A57.38,57.38,0,0,1,303.62,142.86Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-5"
        d="M142.66,141.13a30,30,0,1,1-45.39-39.36Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-4"
        d="M99,297.2a40.62,40.62,0,0,1,1,81.24c-.61,0-1.21-.06-1.81-.07A40.6,40.6,0,0,1,99,297.2Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-8"
        d="M163.1,301.3c-.8,0-1.55-.16-2.33-.21a24,24,0,1,1,2.33.21Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-8"
        d="M315.29,240.4a26.28,26.28,0,1,1-26.61-26A26.23,26.23,0,0,1,315.29,240.4Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-3"
        d="M266.92,297.93a42.63,42.63,0,0,1-8.46,19.4,43.06,43.06,0,0,1-11.28,10.09,42.78,42.78,0,0,1-55-8.39l66.45-54A42.73,42.73,0,0,1,266.92,297.93Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-3"
        d="M4,222.52l90.44,37.71A49,49,0,1,1,4,222.52Z"
      />
      <path
        className="cls-1"
        d="M244.52,1006.45h0c3.8-5.35,25.73-35.91,28.07-39.39,77.09-117.87,99.22-268,59.5-402.92a56.59,56.59,0,0,0-1.6-5.86L346,553.56l.75,2.65c42.18,140.31,19.67,296.94-60.57,419.69-2.16,3.21-18.47,27.32-20.74,30.55Z"
      />
    </svg>
  )
}

export default OrangeFlower
