import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import H3 from "../components/h3"
import H4 from "../components/h4"
import P from "../components/p"
import JoinForm from "../components/join-form"
import Container from "../components/container"
import Button from "../components/button"
import ShareButton from "../components/share-botton"
import TTSImage from "../images/tts.svg"
import TTSHoverImage from "../images/tts-hover.svg"
import getTranslation from "../translations"

const Story = props => {
  const { sanityStory } = props.data
  const path = props.path
  const lang = props.pageContext.language
  const textRef = useRef(null)
  const [ttsHover, setTtsHover] = useState(false)
  const shareUrl = `https://www.100qatariwomen.org/${lang}/100-qatari-women${
    lang === "ar" ? "-" + lang : ""
  }/${sanityStory.slug.current}`
  const [isPlaying, setIsPlaying] = useState(null)
  const [text, setText] = useState(null)
  const [showShare, setShowShare] = useState(false)

  sanityStory.navColor = "white"
  const components = {
    block: {
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      blockquote: ({ children }) => (
        <blockquote className="border-l-purple-500 2xl:text-[1.56rem]">
          {children}
        </blockquote>
      ),
      normal: ({ children }) => (
        <p className="text-blue 2xl:text-[1.2rem] pb-6 first:lg:text-[1.56rem] first:lg:leading-[2.5rem] text-[0.9rem] leading-[1.5rem] md:text-[1.125rem] md:leading-[2rem]">
          {children}
        </p>
      ),
    },
    marks: {
      link: ({ children, value }) => {
        const rel = !value.href.startsWith("/")
          ? "noreferrer noopener"
          : undefined
        return (
          <a href={value.href} rel={rel}>
            {children}
          </a>
        )
      },
      strong: ({ children }) => <strong>{children}</strong>,
    },
  }

  // useEffect(() => {
  //   //setSpeech(window && window.speechSynthesis ? window.speechSynthesis : null)

  // }, [isPlaying])

  const playStory = () => {
    const textContent = textRef.current.textContent
    const utterance = new SpeechSynthesisUtterance(textContent)
    utterance.lang = lang === "ar" ? "ar-SA" : "en-US"
    setText(utterance)
  }
  useEffect(() => {
    if (text) {
      if (!window.speechSynthesis.speaking) {
        window.speechSynthesis.speak(text)
        setIsPlaying(true)
      } else {
        window.speechSynthesis.cancel()
        setIsPlaying(false)
      }
    }
  }, [text])

  useEffect(() => {
    window.speechSynthesis.cancel()
    return function cleanup() {
      //if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel()
      //}
    }
  }, [])

  return (
    <Layout page={sanityStory} path={path}>
      <Container
        classes="pt-0 lg:pt-[10rem]"
        containerClasses="!pt-[0rem] !px-[0rem] rtl:pt-[0rem] rtl:px-[0rem] lg:pt-[5rem]"
      >
        <div
          className="flex flex-col lg:flex-row "
          onClick={() => setShowShare(false)}
        >
          <div className="lg:w-1/3 flex flex-col lg:justify-start">
            {sanityStory.featuredImageGray && (
              <GatsbyImage
                alt={sanityStory.title}
                className="lg:rounded-3xl lg:w-[300px]"
                image={sanityStory.featuredImageGray.asset.gatsbyImageData}
              />
            )}
            <div className="w-full lg:w-[300px] py-[2rem] px-[1.5rem] lg:py-0 lg:px-0">
              <H4 classes="hidden lg:block text-blue lg:text-[1.56rem] lg:pt-[3rem] lg:leading-[2.3rem]">
                {sanityStory.title}
              </H4>
              <H3 classes="lg:hidden text-blue">{sanityStory.title}</H3>
            </div>
            <button
              className="flex flex-row pt-0 px-[1.5rem] lg:px-0 lg:pt-[2rem]"
              onMouseEnter={() => setTtsHover(true)}
              onMouseLeave={() => setTtsHover(false)}
              onClick={() => playStory()}
            >
              {ttsHover || isPlaying ? (
                <>
                  <img
                    src={TTSHoverImage}
                    alt="PLAY STORY"
                    className="w-[40px] rtl:scale-x-[-1]"
                  />
                  <div className="text-orange px-5 pt-2 lg:pt-1 font-bold text-[0.9rem] lg:text-[1.125rem]">
                    {getTranslation("PLAY STORY", lang)}
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={TTSImage}
                    alt="PLAY STORY"
                    className="w-[40px] rtl:scale-x-[-1]"
                  />
                  <div className="text-blue px-5 pt-2 lg:pt-1 font-bold text-[0.9rem] lg:text-[1.125rem]">
                    {getTranslation("PLAY STORY", lang)}
                  </div>
                </>
              )}
            </button>
            <ShareButton
              shareUrl={shareUrl}
              showShare={showShare}
              title={sanityStory.title}
              lang={lang}
              className="hidden lg:flex flex-row pt-0 px-[1.5rem] lg:px-0 lg:pt-[2rem]"
              textClassName="text-blue px-7 pt-2 lg:pt-1 font-bold text-[0.9rem] lg:text-[1.125rem]"
            />

            <Button
              variation="outlinePrimary"
              to={`/${lang}/100-qatari-women${lang === "en" ? "" : "-ar"}`}
              additionalClasses="hidden mr-auto py-1 px-2"
            >
              {getTranslation("BACK TO STORIES", lang)}
            </Button>
          </div>
          <div className="px-[1.5rem] py-8 flex-grow flex-shrink-0 lg:w-2/3 lg:py-0 lg:px-0">
            <article ref={textRef}>
              <PortableText
                value={sanityStory._rawBody}
                components={components}
              />
            </article>
            <div className="lg:hidden flex flex-row justify-between items-start pb-[3rem]">
              <Button
                variation="outlinePrimary"
                to={`/${lang}/100-qatari-women${lang === "en" ? "" : "-ar"}`}
                additionalClasses="lg:hidden py-1 px-2"
              >
                {getTranslation("BACK TO STORIES", lang)}
              </Button>
              <ShareButton
                shareUrl={shareUrl}
                showShare={showShare}
                title={sanityStory.title}
                lang={lang}
                className="flex flex-row py-2 relative"
                textClassName="text-blue font-bold px-3 pt-2 text-[0.9rem]"
              />
            </div>
          </div>
        </div>
      </Container>
      <JoinForm
        data={{
          title: getTranslation(
            "Join our network of exceptional Qatari women",
            lang
          ),
        }}
        id="join-form"
        lang={sanityStory.i18n_lang}
      />
    </Layout>
  )
}

export const Head = ({ data }) => <Seo data={{ page: data.sanityStory }} />

export default Story

export const query = graphql`
  query StoryQuery($id: String) {
    sanityStory(_id: { eq: $id }) {
      _rawBody
      body {
        _key
        _type
        style
        list
        _rawChildren
      }
      title
      tags {
        label
        value
      }
      slug {
        current
      }
      seoKeywords
      seoTitle
      seoImage {
        asset {
          gatsbyImageData
        }
      }
      seoDescription
      quote
      personTitle
      i18n_lang
      featured
      excerpt
      featuredImage {
        asset {
          gatsbyImageData
          url
        }
      }
      featuredImageGray {
        asset {
          gatsbyImageData
          url
        }
      }
    }
  }
`
