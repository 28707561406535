import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"

const variants = {
  visible: { scale: 1, transition: { duration: 1.5, delay: 0.2 } },
  hidden: { scale: 0 },
}

const Shape = ({ className, type, cx, cy, r, d, inView }) => {
  const controls = useAnimation()
  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])
  return type === "circle" ? (
    <motion.circle
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
      cx={cx}
      cy={cy}
      r={r}
    />
  ) : (
    <motion.path
      animate={controls}
      initial="hidden"
      variants={variants}
      className={className}
      d={d}
    />
  )
}

const BlueFlower = ({ className, inView }) => {
  return (
    <svg viewBox="0 0 456.53 1006" className={className}>
      <defs>
        <style>{`.cls-1{fill:#3c4664;}.cls-2{fill:#ee3d96;}.cls-3{fill:#16becf;}.cls-4{fill:#702f83;}.cls-5{fill:#006fab;}.cls-6{fill:#ee3f35;}.cls-7{fill:#87c879;}.cls-8{fill:#fdb933;}`}</style>
      </defs>
      <path
        className="cls-1"
        d="M421.61,300.65a208.15,208.15,0,0,1-235.4,268.1l-5.8-1.06,5.75-1.29c5.17-1.16,10.31-2.58,15.29-4.21,35.62-11.7,69.11-35.19,88.58-53.27,41-38,57.61-84.72,64.34-119.24-.6,0-1.2.07-1.81.07a40.67,40.67,0,0,1-13.12-2.38c-6.35,32.18-21.89,75.39-59.69,110.48-31.7,29.46-82.79,58.36-125.46,56.52l-3.06-.13,2.35-2c35.89-30.19,65-62,86.39-94.62,27.29-41.51,46.11-91.53,54.81-145-.78.05-1.53.22-2.33.21A30.34,30.34,0,0,1,279.93,310c-8.33,51.46-26.4,99.56-52.58,139.39-22.64,34.45-54.46,68.25-94.59,100.48l-2.13,1.71.19-2.73a409.7,409.7,0,0,1,74.71-210.63,42.9,42.9,0,0,1-11.89-9.35A424.53,424.53,0,0,0,116.32,540.2l-.18,2.1-1.71-1.24c-54.59-39.46-33-147-32-151.6,11.76-56.87,46.6-95.34,68.9-114.91a26.4,26.4,0,0,1-11.56-9.93C115.86,286,80,326.69,67.62,386.4,65.39,397.19,55,453.48,70.33,501.69L72,507l-3.73-4.17A214.78,214.78,0,0,1,51.6,481.63,208,208,0,0,1,145.49,171a56.82,56.82,0,0,1-4.41-14.48A223.17,223.17,0,1,0,442.72,405a221.09,221.09,0,0,0-7-109.82Z"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-2"
        cx="374.71"
        cy="177.16"
        r="17.65"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-3"
        cx="396.09"
        cy="82.53"
        r="17.31"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-4"
        cx="405.09"
        cy="133.31"
        r="10.01"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-5"
        cx="390.86"
        cy="221.31"
        r="10.01"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-3"
        cx="285.48"
        cy="31.65"
        r="21.7"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-6"
        cx="318.25"
        cy="83.2"
        r="15.87"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-2"
        cx="336.97"
        cy="9.13"
        r="9.58"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="426.08"
        cy="185.68"
        r="13.61"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="361.52"
        cy="44.77"
        r="9.61"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="227.68"
        cy="91.96"
        r="9.61"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-6"
        cx="299.81"
        cy="197.27"
        r="25.2"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-4"
        cx="347.76"
        cy="237.3"
        r="11"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-7"
        cx="266.66"
        cy="141.1"
        r="21.7"
      />
      <Shape
        inView={inView}
        type="circle"
        className="cls-5"
        cx="235.33"
        cy="234.02"
        r="13.61"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-2"
        d="M152.91,142.86a57.26,57.26,0,0,0,75.29,55.19l-35.8-109A57.37,57.37,0,0,0,152.91,142.86Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-5"
        d="M313.86,141.13a30,30,0,1,0,45.39-39.36Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-4"
        d="M357.5,297.2a40.62,40.62,0,0,0-1,81.24c.61,0,1.2-.06,1.81-.07a40.6,40.6,0,0,0-.82-81.17Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-8"
        d="M293.43,301.3c.79,0,1.54-.16,2.32-.21a24,24,0,1,0-2.32.21Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-8"
        d="M141.24,240.4a26.07,26.07,0,0,0,4.16,14.47,26.25,26.25,0,1,0-4.16-14.47Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-3"
        d="M189.6,297.93A42.87,42.87,0,0,0,264.35,319L197.9,265A42.69,42.69,0,0,0,189.6,297.93Z"
      />
      <Shape
        inView={inView}
        type="path"
        className="cls-3"
        d="M452.49,222.52l-90.44,37.71a49,49,0,1,0,90.44-37.71Z"
      />
      <path
        className="cls-1"
        d="M212,1006.45h0c-3.8-5.35-25.73-35.91-28.07-39.39-77.09-117.87-99.23-268-59.51-402.92a56.66,56.66,0,0,1,1.61-5.86l-15.52-4.72-.76,2.65C67.59,696.52,90.09,853.15,170.34,975.9c2.15,3.21,18.47,27.32,20.73,30.55Z"
      />
    </svg>
  )
}

export default BlueFlower
