import React from "react"
import { motion } from "framer-motion"
import FadeUp from "../utils/animation-presets"

const NameVariations = {
  primary:
    "py-8 font-bold text-base md:text-[1.56rem] lg:text-[1.35rem] 2xl:text-[1.56rem] md:leading-[2.5rem]",
  secondary:
    "py-8 xl:py-0 font-bold leading-[1.8rem] text-[1rem] md:leading-[2.06rem] md:text-[1.312rem] 2xl:text-[1.3rem] xl:leading-[2.5rem]",
  notItalic:
    "py-8 font-bold text-lg md:text-[1.56rem] lg:text-[1.35rem] 2xl:text-[1.56rem] md:leading-[2.5rem]",
}
const QuoteVariations = {
  primary:
    "italic md:pr-[10rem] rtl:pr-[0rem] rtl:pl-[0rem] text-[1.2rem] lg:text-[1.8rem] md:text-[2.125rem] 2xl:text-[2.125rem] leading-[2.5rem] md:leading-[3.75rem] tracking-[0.01rem]",
  secondary:
    "italic text-[1.1rem] md:text-[1.56rem] 2xl:text-[1.562rem] leading-[2.4rem] md:leading-[3.125rem] ",
  notItalic:
    "text-[1.5rem] leading-[3rem] 2xl:text-[2rem] 2xl:leading-[3.37rem] tracking-[0.01rem]",
}
const Quote = ({
  quote,
  name,
  title,
  variation,
  nameOnTop,
  textColor,
  additionalClasses,
}) => {
  return nameOnTop ? (
    <motion.div
      className="flex flex-col"
      initial={FadeUp.initial}
      whileInView={FadeUp.whileInView}
      transition={FadeUp.transition}
    >
      <div className="h-2 border-orange border-t-[1px]"></div>
      <div className="flex flex-col xl:flex-row xl:items-start xl:py-12 xl:pb-16">
        <div
          className={`xl:w-1/3 xl:pt-2 xl:pr-[8.5rem] rtl:xl:pr-0 rtl:xl:pl-[8.5rem] ${
            textColor ?? ""
          } ${NameVariations[variation]} `}
        >
          <div className="xl:pb-3">{name}</div>
          {title}
        </div>
        <blockquote
          className={`xl:w-2/3 pb-10 xl:pb-0 ${QuoteVariations[variation]} ${
            textColor ?? ""
          }`}
        >
          {quote}
        </blockquote>
      </div>
    </motion.div>
  ) : (
    <motion.div
      initial={{ opacity: 0, translateY: "5rem" }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
      className="rtl:pr-[4px]"
    >
      <blockquote
        className={`${QuoteVariations[variation]} ${textColor ?? ""} ${
          additionalClasses ?? ""
        }`}
      >
        {quote}
      </blockquote>
      <div className={`${NameVariations[variation]} ${textColor ?? ""}`}>
        {name}
        <br />
        {title}
      </div>
    </motion.div>
  )
}

export default Quote
