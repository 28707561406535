import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import flowerSmall from "../images/flower-small.svg"

const OrangeMission = ({ color }) => {
  const [ref, inView] = useInView()
  const triangleControls = useAnimation()
  const flowerControls = useAnimation()
  const controlsScale = useAnimation()
  const controlsScale2 = useAnimation()
  const controlsScale3 = useAnimation()

  const variantions = {
    hidden: {
      opacity: 0,
    },
    scaleOff: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        delay: 1.2,
      },
    },
    visibleWithOpacity: {
      opacity: color === "#F7D9BF" ? 0.3 : 1,
      transition: {
        duration: 1,
        delay: 1.2,
      },
    },
    scaleOn: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    scaleOn2: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.4,
        duration: 1.3,
      },
    },
    scaleOn3: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.7,
      },
    },
  }
  useEffect(() => {
    if (inView) {
      flowerControls.start("visible")
      triangleControls.start("visibleWithOpacity")
      controlsScale.start("scaleOn")
      controlsScale2.start("scaleOn2")
      controlsScale3.start("scaleOn3")
    } else {
      flowerControls.start("hidden")
      triangleControls.start("hidden")
      controlsScale.start("scaleOff")
      controlsScale2.start("scaleOff")
      controlsScale3.start("scaleOff")
    }
  }, [
    flowerControls,
    triangleControls,
    controlsScale,
    controlsScale2,
    controlsScale3,
    inView,
  ])
  return (
    <div className="relative">
      <svg
        ref={ref}
        width="100%"
        viewBox="0 0 740 699"
        className="relative xl:h-[700px]"
      >
        <g stroke="none" className="stroke-1 fill-transparent">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <motion.circle
                      style={{ stroke: color }}
                      cx="231.61039"
                      cy="467.064935"
                      r="231.11039"
                      initial="scaleOff"
                      animate={controlsScale3}
                      variants={variantions}
                    ></motion.circle>
                    <motion.circle
                      initial="scaleOff"
                      animate={controlsScale2}
                      variants={variantions}
                      style={{ stroke: color }}
                      cx="370"
                      cy="231.61039"
                      r="231.11039"
                    ></motion.circle>
                    <motion.circle
                      initial="scaleOff"
                      animate={controlsScale}
                      variants={variantions}
                      style={{ stroke: color }}
                      cx="508.38961"
                      cy="467.064935"
                      r="231.11039"
                    ></motion.circle>
                    <motion.text
                      initial="scaleOff"
                      animate={controlsScale3}
                      variants={variantions}
                      style={{ fill: color }}
                      className={`text-[28px] font-bold leading-[60px] tracking-[4px]`}
                    >
                      <tspan x="88.5411169" y="509">
                        INSPIRE
                      </tspan>
                    </motion.text>
                    <motion.text
                      initial="scaleOff"
                      animate={controlsScale2}
                      variants={variantions}
                      style={{ fill: color }}
                      className={`text-[28px] font-bold leading-[60px] tracking-[4px]`}
                    >
                      <tspan x="284.501039" y="159">
                        CELEBRATE
                      </tspan>
                    </motion.text>
                    <motion.text
                      initial="scaleOff"
                      animate={controlsScale}
                      variants={variantions}
                      style={{ fill: color }}
                      className={`text-[28px] font-bold leading-[60px] tracking-[4px]`}
                    >
                      <tspan x="522.157195" y="509">
                        PROMOTE
                      </tspan>
                    </motion.text>
                  </g>
                  <motion.path
                    initial="hidden"
                    animate={triangleControls}
                    variants={variantions}
                    d="M370,281.584416 C395.884814,301.033051 416.793027,324.707848 432.724641,352.608808 C448.656254,380.509768 458.821633,410.973499 463.220779,444 C431.628333,456.813853 400.485289,463.220779 369.791648,463.220779 C339.098007,463.220779 308.414211,456.813853 277.74026,444 C283.35774,407.233125 293.591471,375.846335 308.441453,349.83963 C323.291435,323.832925 343.810951,301.081187 370,281.584416 Z"
                    id="Triangle"
                    style={{ fill: color }}
                  ></motion.path>
                  <motion.image
                    href={flowerSmall}
                    x="50%"
                    y="50%"
                    transform="translate(-38,-25.5)"
                    initial="hidden"
                    animate={flowerControls}
                    variants={variantions}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default OrangeMission
