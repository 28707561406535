import React from "react"
import Button from "./button"
import Container from "./container"
import H3 from "./h3"
import NewsCard from "./news-card"

const NEWS_COUNT = 2

const news = [
  {
    image: "http://placebeard.it/240/300",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In bibendum felis tellus...",
    date: "JAN 31, 2022",
    url: "dsfsdfs",
  },
  {
    image: "http://placebeard.it/240/300",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In bibendum felis tellus...",
    date: "AUG 02, 2022",
    url: "sdfsdfs",
  },
]

const LatestNews = () => {
  return (
    <Container classes="bg-lightBlue">
      <H3 classes="text-blue">
        Get the latest updates from the network of 100 Qatari Women
      </H3>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-[3rem]">
        {news.slice(0, NEWS_COUNT).map((item, index) => (
          <NewsCard
            key={"NewsCard_" + index}
            image={item.image}
            title={item.title}
            date={item.date}
            to={item.url}
          ></NewsCard>
        ))}
      </div>
      <div className="mt-[4rem]">
        <Button variation="primary" to="/">
          See All Updates
        </Button>
      </div>
    </Container>
  )
}

export default LatestNews
