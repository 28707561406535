import React from "react"
import { motion } from "framer-motion"

const commonClasses =
  "text-[1.9rem] leading-[3rem] tracking-[0.05em] md:text-[3rem] md:leading-[4.5rem] md:tracking-[0.0625em] lg:text-[2.5rem] lg:leading-[3.5rem] 2xl:text-[3rem] 2xl:leading-[4.5rem] 2xl:tracking-[0.0625em] rtl:tracking-[0rem] font-bold"

const H2 = ({ classes, children }) => {
  return (
    <motion.h2
      className={classes + " " + commonClasses}
      initial={{ opacity: 0, translateY: "2rem" }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.h2>
  )
}

export default H2
