import React from "react"
import { motion } from "framer-motion"
const commonClasses =
  "tracking-[0.87px] text-[1.9rem] leading-[3.2rem] md:text-[3.625rem] md:leading-[5.25rem] lg:text-[3rem] lg:leading-[4rem] 2xl:text-[4.625rem] 2xl:leading-[6.25rem] rtl:tracking-[0rem] font-bold"

const H1 = ({ classes, children }) => {
  return (
    <motion.h1
      className={commonClasses + " " + (classes ?? "")}
      initial={{ opacity: 0, translateY: "2rem" }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.h1>
  )
}

export default H1
