import React, { useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import ReactHtmlParser from "react-html-parser"
import useMousePosition from "../hooks/useMousePosition"
import H1 from "./h1"
import Button from "./button"
import logo from "../images/logo.svg"
import logoWhitePink from "../images/logo-white-pink.svg"
import pattern from "../images/ScreenWhite.png"
import Container from "./container"
import getTranslation from "../translations"

const VideoBanner = ({ data, lang, id }) => {
  const mousePosition = useMousePosition()
  const [isHovering] = useState(false)
  const container = {
    hidden: { scaleY: 10 },
    show: {
      scaleY: 1,
      transition: {
        delayChildren: 0.5,
        staggerDirection: -1,
        duration: 0.6,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, translateY: "20px", transition: { duration: 0.5 } },
    show: { opacity: 1, translateY: 0, transition: { duration: 0.5 } },
  }

  return (
    <>
      <motion.div
        className="bg-blue lg:hidden w-screen h-[460px] md:h-[768px]"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <Link to="/">
          <motion.img
            variants={item}
            src={logoWhitePink}
            alt="100 Qatari Women logo"
            className="w-[220px] md:w-[320px] py-[3rem] md:py-[5rem] px-[1.5rem] md:px-[2rem]"
          />
        </Link>
        <H1 classes="text-orange px-[1.5rem] md:px-[2rem] pb-[4rem] md:pb-[5.375rem]">
          <motion.span variants={item}>
            {ReactHtmlParser(data.title)}
          </motion.span>
        </H1>
        {data.link && data.link.length > 0 && (
          <motion.div variants={item} className="mx-[1.5rem] md:mx-[2rem]">
            <Button
              variants={item}
              to={data.link[0].url}
              variation="secondary"
              isAnchor={true}
            >
              {data.link[0].title}
            </Button>
          </motion.div>
        )}
      </motion.div>
      <div className="lg:hidden flex justify-center items-toprelative">
        <video
          autoPlay
          muted
          loop
          playsInline
          className="object-cover w-[100%]"
        >
          <source src={data.video.asset.url} />
        </video>
      </div>
      {isHovering && (
        <motion.div
          className="absolute w-[90px] h-[90px] z-50 flex justify-center items-center text-white text-sm rounded-full bg-blue opacity-60 cursor-pointer"
          animate={{
            x: mousePosition.x - 45,
            y: mousePosition.y - 45,
          }}
          transition={{ duration: 0 }}
        >
          Play Video
        </motion.div>
      )}
      <div className="hidden lg:flex justify-center items-top h-screen bg-white relative">
        <video
          autoPlay
          muted
          loop
          playsInline
          className="rtl:scale-x-[-1] absolute object-cover w-screen h-screen "
        >
          <source src={data.video.asset.url} />
        </video>

        <div
          className="hidden lg:flex absolute w-screen h-screen z-1 opacity-[35%]"
          style={{ backgroundImage: `url(${pattern})` }}
        ></div>
        <Container>
          <motion.div
            initial={{ translateY: "5rem", opacity: 0 }}
            animate={{ translateY: "0vh", opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="xl:h-screen lg:pl-[8rem] 2xl:pl-0 xl:pt-[7.5rem]"
          >
            <Link to={`/${lang}/`}>
              <img
                src={logo}
                alt="100 Qatari Women logo"
                className="w-[335px] lg:w-[240px] 2xl:w-[335px] mb-[5.825rem] lg:mb-[3.5rem] 2xl:mb-[5.825rem]"
              />
            </Link>
            <H1 classes="text-blue mb-[5rem] 2xl:mb-[8rem]">
              {ReactHtmlParser(data.title)}
            </H1>
            {data.link && data.link.length > 0 && (
              <Button to={data.link[0].url} variation="primary" isAnchor={true}>
                {data.link[0].title}
              </Button>
            )}
          </motion.div>
        </Container>
        <motion.a
          href="#SanityIntroQuote_2"
          className="xl:block absolute bottom-[10%] left-[5%] lg:left-[8%] 2xl:left-[10%] text-center z-100"
          initial={{ translateY: -20 }}
          animate={{ translateY: [20, -20, 20] }}
          transition={{ repeat: Infinity, duration: 3 }}
        >
          <div className="border-l-[1px] border-blue h-[100px] rtl:ml-[5px]"></div>
          <div className="bg-transparent border-x-2 border-y-2 border-blue rounded-full w-[10px] h-[10px] ml-[-5px] rtl:ml-[0px] rtl:mr-[15px]"></div>
          <div className="text-blue lg:text-sm 2xl:text-base font-bold lg:ml-[-18px] 2xl:ml-[-20px] mt-[5px]">
            {getTranslation("MORE", lang)}
          </div>
        </motion.a>
      </div>
    </>
  )
}

export default VideoBanner
