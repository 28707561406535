import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import H4 from "./h4"
const WomanCard = ({ item, onClick }) => {
  return (
    <motion.div
      className="flex flex-col w-full justify-start items-start text-center relative xl:mb-8"
      initial={{ scale: 1, translateY: "2rem" }}
      whileInView={{ translateY: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div
        role="button"
        onClick={onClick}
        className="flex flex-col w-full cursor-pointer"
      >
        <div className="relative rounded-2xl">
          {item.featuredImageGray && (
            <div
              className={`bg-cover rounded-2xl w-full h-[180px] lg:h-[250px] xl:h-[300px] object-cover after:mix-blend-color after:bg-[#CDDCF0] after:rounded-2xl after:absolute after:w-full after:h-full after:top-0 after:left-0 hover:after:opacity-0`}
              style={{
                backgroundImage: `url(${item.featuredImageGray.asset.url})`,
              }}
            />
          )}
          {/* <motion.div
            variants={animation}
            className="bg-[#CDDCF0] rounded-2xl  mix-blend-color absolute top-0 bottom-0 left-0 right-0 z-100 w-full h-full"
          ></motion.div> */}
        </div>

        <H4 classes="text-orange pt-3 text-[0.8rem] xl:pt-6 xl:text-[1.4rem] xl:leading-[2rem]">
          {item.personName}
        </H4>
      </div>
    </motion.div>
  )
}

export default WomanCard
