import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoBanner from "../components/video-banner"
import Intro from "../components/intro"
import StoriesShort from "../components/stories-short"
import QuoteList from "../components/quote-list"
import Mission from "../components/mission"
import LatestNews from "../components/latest-news"
import Sponsors from "../components/sponsors"
import ContactUS from "../components/contact-us"
import ImageBannerWithQuote from "../components/image-banner-with-quote"
import About from "../components/about"
import StoriesList from "../components/stories-list"
import WhySponsor from "../components/why-sponsor"
import SponsorshipPlans from "../components/sponsorship-plans"
import JoinForm from "../components/join-form"
const Page = props => {
  const { sanityPage } = props.data
  const path = props.path
  return (
    <Layout page={sanityPage} path={path}>
      {sanityPage && sanityPage.pageBody
        ? sanityPage.pageBody.map((item, index) => {
            const id = `${item.__typename}_${index + 1}`
            switch (item.__typename) {
              case "SanityVideoBanner":
                return (
                  <VideoBanner
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityIntroQuote":
                return (
                  <Intro
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanitySponsors":
                return (
                  <Sponsors
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityStoriesShortList":
                return (
                  <StoriesShort
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityQuoteList":
                return (
                  <QuoteList
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityMission":
                return (
                  <Mission
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityLatestNews":
                return (
                  <LatestNews
                    data={item}
                    key={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityJoinForm":
                return (
                  <JoinForm
                    data={item}
                    key={id}
                    id="join-form"
                    lang={sanityPage.i18n_lang}
                    type="Join"
                  />
                )
              case "SanityContactForm":
                return (
                  <ContactUS
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                    type="Contact"
                  />
                )
              case "SanityWhySponsor":
                return (
                  <WhySponsor
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityStoriesList":
                return (
                  <StoriesList
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanitySponsorshipPlans":
                return (
                  <SponsorshipPlans
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityImageBannerWithQuote":
                return (
                  <ImageBannerWithQuote
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              case "SanityGallery":
                return null
              case "SanityAbout":
                return (
                  <About
                    data={item}
                    key={id}
                    id={id}
                    lang={sanityPage.i18n_lang}
                  />
                )
              default:
                return null
            }
          })
        : null}
    </Layout>
  )
}

export const Head = ({ data }) => <Seo data={{ page: data.sanityPage }} />

export default Page

export const query = graphql`
  fragment StyledLink on SanityStyledLink {
    title
    target
    url
  }
  query sanityPageQuery($id: String) {
    sanityPage(id: { eq: $id }) {
      title
      id
      slug {
        current
      }
      featuredImage {
        asset {
          gatsbyImage(width: 200)
        }
      }
      seoTitle
      seoDescription
      seoKeywords
      pageColor
      navColor
      seoImage {
        asset {
          gatsbyImage(width: 200)
        }
      }
      i18n_lang
      i18n_base {
        slug {
          current
        }
        i18n_refs {
          slug {
            current
          }
        }
      }
      pageBody {
        __typename
        ... on SanityContactForm {
          title
          phone
          email
        }
        ... on SanityIntroQuote {
          quote
          personName
          personTitle
          title
          bottomText
        }
        ... on SanityJoinForm {
          title
        }
        ... on SanityLatestNews {
          title
          link {
            ...StyledLink
          }
        }
        ... on SanityMission {
          title
          text
          link {
            ...StyledLink
          }
          backgroundColor
        }
        ... on SanityQuoteList {
          title
        }
        ... on SanityStoriesShortList {
          title
          filters
          link {
            ...StyledLink
          }
        }
        ... on SanityVideoBanner {
          title
          text
          video {
            asset {
              url
            }
          }
          link {
            ...StyledLink
          }
        }
        ... on SanitySponsors {
          title
          text
          link {
            ...StyledLink
          }
          list {
            title
            image {
              asset {
                gatsbyImage(width: 200)
                url
              }
            }
            link {
              ...StyledLink
            }
          }
        }
        ... on SanityAbout {
          title
          aboutBody {
            title
            text
          }
        }
        ... on SanityGallery {
          title
          images {
            title
            date
            tags {
              value
              label
            }
            link {
              ...StyledLink
            }
            image {
              asset {
                gatsbyImage(width: 200)
              }
            }
          }
        }
        ... on SanityImageBannerWithQuote {
          title
          image {
            asset {
              gatsbyImage(width: 600)
              url
            }
          }
          quote
          personName
          personTitle
          overlayColor
        }
        ... on SanityNews {
          title
        }
        ... on SanitySponsorshipPlans {
          title
          plans {
            title
            sponsorshipPrice
            sponsorshipBenefits
          }
        }
        ... on SanityStoriesList {
          title
          filters
        }
        ... on SanityWhySponsor {
          title
          whySponsorBody
        }
      }
    }
  }
`
