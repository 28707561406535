import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import H4 from "./h4"
const ImageCard = ({ image, title, textClass, to }) => {
  return (
    <motion.div
      className="relative"
      transition={{ duration: 0.5 }}
      whileInView={{ translateY: 0 }}
      initial={{ scale: 1, translateY: "3rem" }}
    >
      <Link to={to}>
        <div className="flex flex-col justify-center items-center">
          <div
            className="rounded-2xl bg-lightBlue flex items-center justify-center w-[100%] h-[140px] xl:h-[200px] "
            src={image}
            alt={title}
          >
            <img src={image} alt={title} className="px-4 max-h-[140px]" />
          </div>
          <H4
            classes={`${textClass} text-[0.8rem] pt-4 text-center xl:text-xl`}
          >
            {title}
          </H4>
        </div>
      </Link>
    </motion.div>
  )
}

export default ImageCard
