import React, { useState } from "react"
import Container from "./container"
import H2 from "./h2"
import Button from "./button"
import H3 from "./h3"
import Form from "./form"
import getTranslation from "../translations"

const SponsorshipPlans = ({ data, id, lang }) => {
  const [selectdPlanIndex, setSelectdPlanIndex] = useState(0)
  const [selectdPlan, setSelectdPlan] = useState(data.plans[0])
  return (
    <Container classes="bg-orange pb-[5rem] xl:pt-[3rem]" id={id} lang={lang}>
      <H2 classes="text-blue lg:w-1/2">{data.title}</H2>
      <div className="mt-14">
        {data.plans.length > 1 &&
          data.plans.map((item, index) => (
            <Button
              onClick={() => {
                setSelectdPlanIndex(index)
                setSelectdPlan(item)
              }}
              key={`filter_${index}`}
              variation={
                selectdPlanIndex === index
                  ? "outlinePrimarySelected"
                  : "outlinePrimary"
              }
              additionalClasses={`mb-4 mr-4 rtl:mr-0 rtl:ml-4 px-[2rem] text-sm`}
            >
              {item.title}
            </Button>
          ))}
        <div className="bg-white rounded-[40px] relative z-20">
          <div className="px-[2rem] py-[2rem] lg:px-[3rem] lg:py-[3rem] mt-[2rem]">
            <H3 classes="rtl:hidden text-blue capitalize leading-[2.2rem]">
              {selectdPlan.title.toLowerCase() +
                " " +
                getTranslation("Sponsor", lang)}
              <span className="hidden md:inline">: </span>
              <br className="md:hidden" />
              {selectdPlan.sponsorshipPrice}
            </H3>
            <H3 classes="rtl:block hidden text-blue capitalize leading-[2.2rem]">
              {getTranslation("Sponsor", lang) +
                " " +
                selectdPlan.title.toLowerCase()}
              <span className="hidden md:inline">: </span>
              <br className="md:hidden" />
              {selectdPlan.sponsorshipPrice}
            </H3>
            <ul className="list-square mb-2">
              {selectdPlan.sponsorshipBenefits.map((item, index) => (
                <li
                  key={`benefit-` + index}
                  className="text-blue text-base md:text-[1.2rem] mt-4 mx-4 md:mt-6 lg:mx-6 font-bold pl-3 leading-5"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="border-t-[1px] border-orange"></div>
          <div className="px-[1.5rem] lg:px-[3rem] pb-16 pt-8">
            <Form
              fields="name,email,message,consent"
              type="Sponsorship"
              submit={getTranslation("BECOME A SPONSOR", lang)}
              lang={lang}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SponsorshipPlans
