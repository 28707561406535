import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import H4 from "./h4"
import H5 from "./h5"
const NewsCard = ({ image, title, date, to }) => {
  return (
    <div className="relative">
      <Link to={to}>
        <motion.img
          whileInView={{ translateY: 0 }}
          className="rounded-lg grayscale hover:grayscale-0"
          initial={{ scale: 1, translateY: "5rem" }}
          src={image}
          alt={title}
          transition={{ duration: 0.8 }}
        />
        <H4 classes="text-blue pt-3">{title}</H4>
        <H5 classes="text-blue pt-3">{date}</H5>
      </Link>
    </div>
  )
}

export default NewsCard
