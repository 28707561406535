import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactHtmlParser from "react-html-parser"
import Quote from "./quote"
import Container from "./container"
import H2 from "./h2"

const ImageBannerWithQuote = ({ data, id, lang }) => {
  return (
    <Container
      classes="2xl:h-screen bg-maroon pb-0"
      containerClasses="h-full"
      id={id}
      lang={lang}
    >
      <div className="flex h-full lg:items-end flex-col lg:flex-row pt-[5rem] md:pt-[5rem] 2xl:pt-0 ">
        <div className="w-full lg:w-3/5 lg:pr-[55px] rtl:lg:pr-[0px] rtl:lg:pl-[55px]">
          <H2 classes="text-lightMaroon mb-[2rem] lg:mb-[2rem] lg:w-4/5 ">
            {ReactHtmlParser(data.title)}
          </H2>
          <div className="mb-[0rem] lg:mb-[6.5rem]">
            <Quote
              quote={data.quote}
              name={data.personName}
              title={data.personTitle}
              variation="notItalic"
              textColor="text-lightMaroon"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center lg:items-start lg:justify-end 2xl:mr-[-150px] rtl:2xl:mr-auto rtl:2xl:ml-[-150px]">
          <GatsbyImage
            className="mt-8"
            image={data.image.asset.gatsbyImage}
            alt={data.title}
          />
        </div>
      </div>
    </Container>
  )
}

export default ImageBannerWithQuote
