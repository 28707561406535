import React, { useEffect, useState } from "react"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import shareImage from "../images/share.svg"
import shareHoverImage from "../images/share-hover.svg"
import getTranslation from "../translations"

const ShareButton = ({
  shareUrl,
  title,
  lang,
  showShare,
  className,
  textClassName,
}) => {
  const [shareHover, setShareHover] = useState(false)
  const [share, setShare] = useState(false)

  useEffect(() => {
    setShare(showShare)
  }, [showShare])

  return (
    <>
      {share && (
        <div className="relative">
          <div className="w-[60px] absolute top-[-185px] left-[0px] rtl:left-[0px] rtl:right-[-8px] z-50 bg-white px-3 py-3 rounded-xl border-[1px] border-[#eee]">
            <div className="flex flex-col">
              <EmailShareButton subject={title} url={shareUrl}>
                <EmailIcon size={32} round={true} className="my-1" />
              </EmailShareButton>
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round={true} className="my-1" />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} quote={title}>
                <TwitterIcon size={32} round={true} className="my-1" />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl} quote={title}>
                <LinkedinIcon size={32} round={true} className="my-1" />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      )}
      <button
        onClick={() => setShare(!share)}
        className={className}
        onMouseEnter={() => setShareHover(true)}
        onMouseLeave={() => setShareHover(false)}
      >
        {shareHover ? (
          <>
            <img
              src={shareHoverImage}
              alt="SHARE STORY"
              className="w-[32px] rtl:scale-x-[-1]"
            />
            <div className={textClassName + " text-orange"}>
              {getTranslation("SHARE STORY", lang)}
            </div>
          </>
        ) : (
          <>
            <img
              src={shareImage}
              alt="SHARE STORY"
              className="w-[32px] rtl:scale-x-[-1]"
            />

            <div className={textClassName}>
              {getTranslation("SHARE STORY", lang)}
            </div>
          </>
        )}
      </button>
    </>
  )
}

export default ShareButton
