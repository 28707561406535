import React from "react"
import ReactHtmlParser from "react-html-parser"
import Container from "./container"
import H2 from "./h2"
import SponsorCard from "./sponsor-card"
import sponsor from "../images/sponsor.png"
import H3 from "./h3"
import P from "./p"
import Button from "./button"

const sponsors = [
  {
    image: sponsor,
    title: "Alfardan Group",
    url: "dsfsdfs",
  },
  {
    image: sponsor,
    title: "Alfardan Group",
    url: "dsfsdfs",
  },
  {
    image: sponsor,
    title: "Alfardan Group",
    url: "dsfsdfs",
  },
  {
    image: sponsor,
    title: "Alfardan Group",
    url: "dsfsdfs",
  },
  {
    image: sponsor,
    title: "Alfardan Group",
    url: "dsfsdfs",
  },
  {
    image: sponsor,
    title: "Alfardan Group",
    url: "dsfsdfs",
  },
]

const Sponsors = ({ data, id, lang }) => {
  return (
    <Container
      classes="bg-blue md:pt-[4rem] md:pt-[2rem] xl:pt-[3rem] 2xl:pt-[2rem] xl:pb-[0rem] "
      id={id}
      lang={lang}
    >
      {data.text ? (
        <div className="lg:w-2/3">
          <H3 classes="text-orange my-[2.5rem] mt-[4rem] xl:mt-[8rem]">
            {ReactHtmlParser(data.title)}
          </H3>
          <P classes="text-orange text-sm 2xl:text[1.125rem] 2xl:mb-[5rem]">
            {data.text}
          </P>
        </div>
      ) : (
        <H2 classes="text-orange xl:pb-8">{ReactHtmlParser(data.title)}</H2>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 xl:gap-8 mt-[3rem] pb-[6rem]">
        {data.list &&
          data.list.map((item, index) =>
            item.image ? (
              <SponsorCard
                key={"SponsorCard_" + index}
                image={item.image.asset.url}
                title={item.title}
                to={item.link && item.link.length > 0 ? item.link[0].url : "#"}
                textClass="text-orange"
              ></SponsorCard>
            ) : null
          )}
      </div>
      {data.link && data.link.length > 0 && (
        <div className="pb-[5rem] xl:pb-[7rem] 2xl:pb-[10rem]">
          <Button variation="secondary" to={data.link[0].url}>
            {data.link[0].title}
          </Button>
        </div>
      )}
    </Container>
  )
}

export default Sponsors
