import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatePresence } from "framer-motion"
import Button from "./button"
import H3 from "./h3"
import WomanCard from "./woman-card"
import Container from "./container"
import Overlay from "./overlay"
import getTranslation from "../translations"
// fixing order
const StoriesShort = ({ data, id, lang }) => {
  const allStories = useStaticQuery(graphql`
    query StoriesQuery {
      en: allSanityStory(
        sort: { fields: featured, order: ASC }
        filter: { i18n_lang: { eq: "en" } }
        limit: 12
      ) {
        nodes {
          title
          excerpt
          featured
          i18n_lang
          slug {
            current
          }
          personName
          personTitle
          quote
          featuredImage {
            asset {
              gatsbyImageData
              url
            }
          }
          featuredImageGray {
            asset {
              gatsbyImageData
              url
            }
          }
          tags {
            value
            label
          }
          body {
            _rawChildren
          }
        }
      }
      ar: allSanityStory(
        sort: { fields: featured, order: ASC }
        filter: { i18n_lang: { eq: "ar" } }
        limit: 12
      ) {
        nodes {
          title
          excerpt
          featured
          i18n_lang
          slug {
            current
          }
          personName
          personTitle
          quote
          featuredImage {
            asset {
              gatsbyImageData
              url
            }
          }
          featuredImageGray {
            asset {
              gatsbyImageData
              url
            }
          }
          tags {
            value
            label
          }
          body {
            _rawChildren
          }
        }
      }
    }
  `)
  const stories = allStories[lang].nodes
  //const stories = list.filter(item => item.featured).slice(0, 12)
  data.filters = "ALL,BUSINESS,OFFICIAL,PROFESSIONAL,RISING STAR";
  const [selectedStory, setSelectedStory] = useState(null)
  return (
    <>
      <Container classes="bg-blue xl:pt-[3rem]" id={id} lang={lang}>
        <H3 classes="text-orange pb-14 md:w-4/5 xl:pr-[6rem] rtl:pr-0 rtl-xl:pl-[6rem]">
          {data.title}
        </H3>

        <div className="flex flex-wrap flex-row justify-start items-start mt-3 lg:mt-4">
          {data.filters.split(",").map((f, index) => (
            <Button
              to={data.link[0].url + "?filter=" + f}
              key={`filter_${index}`}
              variation={
                index === 0 ? `outlineSecondarySelected` : `outlineSecondary`
              }
              additionalClasses={`mb-4 px-[1.45rem] py-[0.85rem] ml-0 mr-3 xl:mr-5 rtl:mr-0 rtl:ml-3 rtl:xl:ml-5  xl:px-[3rem]`}
            >
              {getTranslation(f, lang)}
            </Button>
          ))}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-6 xl:gap-10 mt-[3rem] ">
          {stories.map((item, index) => (
            <WomanCard
              onClick={() => setSelectedStory(item)}
              key={"WomanCard_" + index}
              item={item}
            ></WomanCard>
          ))}
        </div>
        <div className="mt-[4rem] md:mt-[6rem] pb-[2rem] md:pb-[6rem]">
          <Button variation="secondary" to={data.link[0].url}>
            {data.link[0].title}
          </Button>
        </div>
      </Container>
      <AnimatePresence>
        {selectedStory && (
          <Overlay
            lang={lang}
            item={selectedStory}
            onClose={() => setSelectedStory(null)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default StoriesShort
