import React from "react"
import H3 from "./h3"
import P from "./p"
import Container from "./container"
import OrangeMission from "./orange-mission"
import OrangeMissionAr from "./orange-mission-ar"

const Mission = ({ data, lang, id }) => {
  const variations = {
    "bg-maroon": { container: "bg-maroon ", text: "text-lightMaroon " },
    "bg-orange": { container: "bg-orange ", text: "text-blue" },
  }
  return (
    <Container
      id={id}
      classes={`${
        variations[data.backgroundColor].container
      } pb-[5rem] lg:pb-[0rem]`}
    >
      <div className="flex flex-col lg:flex-row-reverse lg:justify-center lg:items-start lg:pb-[8rem] lg:pt-[2rem]">
        <div className="lg:w-2/3 mt-[1rem] lg:ml-[8rem] rtl:lg:ml-0 rtl:lg:mr-[8rem]">
          {lang === "en" ? (
            <OrangeMission
              color={
                data.backgroundColor === "bg-maroon" ? "#F7D9BF" : "#3C4664"
              }
            />
          ) : (
            <OrangeMissionAr
              color={
                data.backgroundColor === "bg-maroon" ? "#F7D9BF" : "#3C4664"
              }
            />
          )}
        </div>
        <div className="lg:w-2/5 xl:mt-[5rem] 2xl:mt-[0rem]">
          <H3
            classes={`${
              variations[data.backgroundColor].text
            } pt-[2rem] 2xl:pt-[5rem] text-center lg:text-left rtl:text-right`}
          >
            {data.title}
          </H3>
          <P
            classes={`${
              variations[data.backgroundColor].text
            } pt-[2.5rem] text-center lg:text-left rtl:text-right`}
          >
            {data.text}
          </P>
        </div>
      </div>
    </Container>
  )
}

export default Mission
