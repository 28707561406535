import React from "react"
import ReactHtmlParser from "react-html-parser"
import { useInView } from "react-intersection-observer"
import H2 from "./h2"
import Quote from "./quote"
import Container from "./container"
import BlueFlower from "./blue-flower"
const Intro = ({ data, id, lang }) => {
  const [ref, inView] = useInView()
  return (
    <Container
      classes="bg-orange md:pt-[3rem] lg:pt-[2rem] xl:pt-[5rem] 2xl:pt-0"
      id={id}
      lang={lang}
    >
      <div
        ref={ref}
        className="absolute z-10 bottom-[-1px] right-0 rtl:right-auto rtl:left-0"
      >
        <BlueFlower
          inView={inView}
          className="h-[300px] md:h-[450px] lg:h-[850px] xl:h-[900px] 2xl:h-[950px]"
        />
      </div>
      <div className="lg:w-2/3 2xl:w-2/3 lg:pb-[5rem] 2xl:pt-[5rem] rtl:pl-5 ">
        <H2 classes="text-blue pb-[2rem] md:w-[350px] w-[250px] rtl:w-[450px]">
          {ReactHtmlParser(data.title)}
        </H2>
        <Quote
          variation="primary"
          quote={data.quote}
          name={data.personName}
          title={data.personTitle}
          textColor="text-blue"
        ></Quote>
        <p className="whitespace-pre-wrap text-sm md:text-base xl:text-lg text-blue py-8 pb-20 2xl:pb-[5rem] w-2/3 pr-8 rtl:pr-0 rtl:pl-8">
          {data.bottomText}
        </p>
      </div>
    </Container>
  )
}

export default Intro
